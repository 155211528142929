<template>
  <Modal :title="title" :active="modalActive" :data="initValues" :containForm="true"
    :modalValidationSchema="validationSchema" @modal-form-submitted="handleSubmit($event)"
    @modal-form-values="updateCurrentFormValues" @modal-form-errors="updateErrors" @modal-close="close" size="xl"
    no-scroll>
    <template v-slot:modal-body>
      <Loader :active="loading" />

      <p class="h4">Modalité N° {{ initValues?.ordre ? initValues.ordre : modalitiesNb + 1 }}</p>

      <!-- Type de modalité -->
      <Radio v-if="typemodalite && canEditModa" :compkey="compkey" :disabled="!editAll" required id="typemodalite"
        :items="typemodalite" label="Type de modalité" inputStyle="inline" />

      <!-- Numéro local -->
      <Input :disabled="!editAll" id="numero" label="Numéro local" />

      <!-- Destruction -->
      <Radio v-if="canEditModa" id="destruction" :disabled="!editAll" :compkey="compkey" :items="[
    { label: 'Oui', value: true },
    { label: 'Non', value: false },
  ]" label="Destruction de la modalité" inputStyle="inline" />

      <!-- Modalité optionnelle -->
      <Radio v-if="canEditModa" id="option" :compkey="compkey" :items="[
    { label: 'Oui', value: true },
    { label: 'Non', value: false },
  ]" label="Modalité optionnelle" inputStyle="inline" />

      <!-- Début des champs liés aux facteurs -->

      <template v-for="(header, index) in headers" :key="index">
        <!-- SEMENCES -->
        <template v-if="header.type.uid === 'SEMENCES'">

          <div class="h4 no-margin-bottom" v-if="dataLoaded">
            <div v-if="header?.designation">
              <span>{{ header?.designation }} - </span>
              <span class="color-gray-light">{{ header.type.designation }}</span>
            </div>
            <div v-else>
              <span>{{ header.type.designation }}</span>
            </div>
          </div>

          <div class="hidden">
            <Input :id="`factors[${index}].id`" inline type="hidden" />
          </div>

          <div class="margin-bottom-double">
            <template v-if="edit_modality && dataLoaded">
              <RepeatableInputs v-if="modalActive" :id="`semences[${index}].produits`"
                :items="getFiltredProduct(header.type.uid)" :default-selection="repeatables[index]"
                @selected-items="updateSemenceSupplier($event, index, header.id)" :errors="errors" n-cols="6" />
            </template>

            <template v-if="!edit_modality">
              <RepeatableInputs v-if="modalActive" :id="`semences[${index}].produits`"
                :items="getFiltredProduct(header.type.uid)"
                @selected-items="updateSemenceSupplier($event, index, header.id)" :errors="errors" n-cols="6" />
            </template>
          </div>
        </template>

        <!-- SANTE VÉGÉTALE ET FERTILISATION -->
        <template v-if="canEditModa &&
        editAll && (
          header.type.uid === 'SANTEVEGETALE' ||
          header.type.uid === 'FERTILISATION')
        ">

          <div v-if="dataLoaded">
            <div class="h4 no-margin-bottom" v-if="dataLoaded">
              <div v-if="header?.designation">
                <span>{{ header?.designation }}</span>
                <span class="color-gray-light"> - {{ header.type.designation }}</span>
              </div>
              <div v-else>
                <span>{{ header.type.designation }}</span>
              </div>
            </div>

            <div class="margin-bottom-half color-gray-light">
              <template v-for="passage in header.passages" v-bind:key="passage.id">
                <template v-if="passage.id == header.passage_id">
                  Stade d'application : {{ passage.stade?.code_bbch }} -
                  {{ passage.stade?.designation_court }}.
                </template>
              </template>
            </div>
          </div>

          <div class="hidden">
            <Input :id="`factors[${index}].id`" type="hidden" />
            <Input :id="`factors[${index}].passage_id`" type="hidden" />
          </div>
          <div class="margin-bottom-double">
            <template v-if="edit_modality && dataLoaded">
              <RepeatableInputs v-if="modalActive" :id="`passages[${index}].produits`"
                :items="getFiltredProduct(header.type.uid)" :default-selection="repeatables[index]" :errors="errors"
                @selected-items="updatePassage($event, index, header.id)" n-cols="4" />
            </template>
            <template v-if="!edit_modality">
              <RepeatableInputs v-if="modalActive" :id="`passages[${index}].produits`"
                :items="getFiltredProduct(header.type.uid)" @selected-items="updatePassage($event, index, header.id)"
                :errors="errors" n-cols="4" />
            </template>
          </div>
        </template>

        <!-- PERSONNALISE -->
        <template v-if="editAll && header.type.uid === 'PERSONNALISE'">
          <p class="h4" v-if="dataLoaded" v-html="`${header.type.designation}`">
          </p>
          <div class="hidden">
            <Input :id="`factors[${index}].id`" type="hidden" />
          </div>

          <!-- PERSONNALISE - CHAMP DE TYPE LISTE -->
          <!-- eslint-disable -->
          <SelectExtended v-if="header.variables[0].type.format_variable.uid === 'LISTE'"
            :id="`valeurspersonalise[${index}].valeur`" label="Valeurs" option-key="uid" option-value="valeur"
            :apiEndpoint="`dictionnaire/referentiel/${header.variables[0].type.referentiel.id}/valeur`" />

          <!-- PERSONNALISE - CHAMP DE TYPE TEXTE -->
          <Input v-if="header.variables[0].type.format_variable.uid === 'VARCHAR'"
            :id="`valeurspersonalise[${index}].valeur`" label="Valeur" value="red" />

          <!-- PERSONNALISE - CHAMP DE TYPE DATE -->
          <Input v-if="header.variables[0].type.format_variable.uid === 'DATE'"
            :id="`valeurspersonalise[${index}].valeur`" label="Date" type="date" />
        </template>
      </template>


      <!-- Désignation auto -->
      <Radio id="designationauto" v-if="canEditModa" :compkey="compkey" :items="[
        { label: 'Automatique', value: true },
        { label: 'Manuelle', value: false },
      ]" label="Définition de désignation de la modalité" inputStyle="inline" />

      <div v-show="currentFormValues?.designationauto">
        <p class="h4">Désignation</p>
        <KeyValue label="Désignation automatique de la modalité" :value="defaultDesignation" />
      </div>

      <!-- Désignation de la modalité -->
      <div v-show="!currentFormValues?.designationauto">
        <p class="h4">Désignation</p>
        <Input :disabled="!canEditModa" id="designation" required label="Désignation de la modalité" />
      </div>



    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="close" />
      <Btn v-if="!edit_modality" text="Ajouter" btnType="submit" color="primary" />
      <Btn v-if="edit_modality" text="Enregistrer les modifications" btnType="submit" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Loader from '@/components/layout/Loader.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'AddEditModaliteModal',
  components: {
    SelectExtended,
    RepeatableInputs,
    Modal,
    Btn,
    Input,
    KeyValue,
    Radio,
    Loader,
  },
  props: {
    protocol_id: {
      type: [Number, String],
    },
    modalActive: {
      type: Boolean,
    },
    headers: {
      type: Object,
    },
    modalities: {
      type: Object,
    },
    produits: {
      type: Object,
    },
    modalitiesNb: {
      type: [Number, String],
    },
    editAll: {
      type: Boolean,
      default: true,
    },
    modality_id: {
      type: [Number, String],
      default: null,
    },
    edit_modality: {
      type: Boolean,
      default: false,
    },
    compkey: {
      type: String,
    },
  },
  data() {
    const validationSchema = this.yup.object({
      option: this.yup.boolean().nullable(),
      designationauto: this.yup.boolean().nullable(),
      designation: this.yup.string().when(
        '[designationauto]', (designationauto, schema) => (designationauto === true ? schema.nullable() : schema.required()),
      ),
      numero: this.yup.string().nullable(),
      destruction: this.yup.boolean().nullable(),
      typemodalite: this.yup.string().required(),
      factors: this.yup.array().nullable(),

      semences: this.yup.array().of(
        this.yup
          .object()
          .shape({
            produits: this.yup.array().of(
              this.yup.object().shape({
                id: this.yup.string().nullable(),
                produit: this.yup.object().nullable(),
                traitementsemence: this.yup.string().nullable(),
                densite: this.yup.string().nullable(),
                pmg: this.yup.string().nullable(),
                unite: this.yup.object().nullable(),
                fournisseur: this.yup.object()
                  .when(['produit'], (produit, schema) => (produit ? schema.required() : schema.nullable()))
                  .nullable(),
              }),
            ),
          })
          .nullable(),
      ),

      passages: this.yup.array().of(
        this.yup
          .object()
          .shape({
            produits: this.yup.array().of(
              this.yup.object().shape({
                id: this.yup.string().nullable(),
                dose: this.yup.string()
                  .when(['produit'], (produit, schema) => (produit ? schema.required() : schema.nullable()))
                  .nullable(),
                produit: this.yup.object().nullable(),
                unite: this.yup.object()
                  .when(['produit'], (produit, schema) => (produit ? schema.required() : schema.nullable()))
                  .nullable(),
                fournisseur: this.yup.object()
                  .when(['produit'], (produit, schema) => (produit ? schema.required() : schema.nullable()))
                  .nullable(),
              }),
            ),
          })
          .nullable(),
      ),
      valeurspersonalise: this.yup.array().of(
        this.yup.object().shape({
          valeur: this.yup.mixed().nullable(),
        }).nullable(),
      ),
    })
    return {
      validationSchema,
      title: null,
      modality: null,
      canEditModa: true,
      loading: false,
      initValues: null,
      saveInitValues: null,
      currentFormValues: null,
      repeatables: [],
      defaultDesignation: null,
      formattedValue: [],
      errors: null,
      typemodalite: null,
      dataLoaded: false,
      semences: {
        items: {
          group: [
            {
              label: 'Produit',
              name: 'produit',
              meta: {
                optionKey: 'id',
                optionValue: 'designation',
                fuseSearch: true,
                searchKey: [
                  'designation',
                  'noms_commerciaux.code_interne',
                  'code_cooperative',
                ],
              },
            },
            {
              label: 'Traitement',
              name: 'traitementsemence',
            },
            {
              label: 'Densité',
              name: 'densite',
            },
            {
              label: 'Unité',
              name: 'unite',
              meta: {
                optionKey: 'uid',
                optionValue: 'valeur',
                apiEndpoint: 'unite',
                apiParams: { sort: 'valeur.ASC', limit: 0 },
              },
            },
            {
              label: 'PMG',
              name: 'pmg',
            },
            {
              label: 'Fournisseurs',
              name: 'fournisseur',
              meta: {
                optionKey: 'id',
                optionValue: 'nom',
                apiEndpoint: 'fournisseur',
                apiParams: { sort: 'nom.ASC', limit: 0 },
              },
            },
          ],
        },
      },
      santevegetale: {
        items: {
          group: [
            {
              label: 'Produit',
              name: 'produit',
              meta: {
                optionKey: 'id',
                optionValue: 'designation',
                fuseSearch: true,
                searchKey: [
                  'designation',
                  'noms_commerciaux.code_interne',
                  'code_cooperative',
                ],
              },
            },
            {
              label: 'Dose',
              name: 'dose',
              type: 'number',
              options: { min: 0, step: 0.0001 },
            },
            {
              label: 'Unité',
              name: 'unite',
              meta: {
                optionKey: 'uid',
                optionValue: 'valeur',
                apiEndpoint: 'unite',
                apiParams: { sort: 'valeur.ASC', limit: 0 },
              },
            },
            {
              label: 'Fournisseurs',
              name: 'fournisseur',
              meta: {
                optionKey: 'id',
                optionValue: 'nom',
                apiEndpoint: 'fournisseur',
                apiParams: { sort: 'nom.ASC', limit: 0 },
              },
            },
          ],
        },
      },
      fertilisation: {
        items: {
          group: [
            {
              label: 'Produit',
              name: 'produit',
              meta: {
                optionKey: 'id',
                optionValue: 'designation',
                fuseSearch: true,
                searchKey: [
                  'designation',
                  'noms_commerciaux.code_interne',
                  'code_cooperative',
                ],
              },
            },
            {
              label: 'Dose',
              name: 'dose',
              type: 'number',
              options: { min: 0, step: 0.0001 },
            },
            {
              label: 'Unité',
              name: 'unite',
              meta: {
                optionKey: 'uid',
                optionValue: 'valeur',
                apiEndpoint: 'unite',
                apiParams: { sort: 'valeur.ASC', limit: 0 },
              },
            },
            {
              label: 'Fournisseurs',
              name: 'fournisseur',
              meta: {
                optionKey: 'id',
                optionValue: 'nom',
                apiEndpoint: 'fournisseur',
                apiParams: { sort: 'nom.ASC', limit: 0 },
              },
            },
          ],
        },
      },
    }
  },
  emits: ['modal-close'],
  watch: {
    modalActive() {
      this.resetData()
      if (this.edit_modality) {
        this.loadData()
      } else {
        this.setData()
      }
    },
    modality_id() {
      this.resetData()
      if (this.edit_modality) {
        this.loadData()
      } else {
        this.setData()
      }
    },
  },
  mounted() {
    this.title = 'Ajouter une modalité'
    if (this.edit_modality) {
      this.title = 'Modifier une modalité'
      this.loadData()
    } else {
      this.setData()
    }
  },
  methods: {
    containsNull(array) {
      return Object.values(array).some((v) => v === null)
    },
    close() {
      this.resetData()
      this.$emit('modal-close', this.activeModal)
    },
    handleSubmit(values) {
      this.loading = true
      const empty = (element) => element === undefined
      const modaliteValues = {
        modalite: {},
        semences: [],
        passages: [],
        valeurs: [],
      }

      if (values.designationauto) {
        modaliteValues.modalite.designation = this.defaultDesignation
      } else {
        modaliteValues.modalite.designation = values.designation
      }

      modaliteValues.modalite.numero = values.numero
      modaliteValues.modalite.destruction = values.destruction
      modaliteValues.modalite.designationauto = values.designationauto
      modaliteValues.modalite.option = values.option

      if (values.typemodalite) {
        modaliteValues.modalite.type_uid = values.typemodalite
      }

      if (typeof values.semences !== 'undefined') {
        values.semences.forEach((semences, index) => {
          const semenceValues = {
            facteur_id: values.factors[index].id,
            produits: [],
          }

          if (semences?.produits && semences.produits.length > 0) {
            semences.produits.forEach((produit) => {
              const produitValues = {
                id: produit?.id,
                facteur_id: values.factors[index].id,
                produit_id: produit.produit ? produit.produit.key : undefined,
                traitementsemence: produit.traitementsemence,
                densite: produit.densite,
                pmg: produit.pmg,
                unite_uid: produit.unite ? produit.unite.key : undefined,
                fournisseur_id: produit.fournisseur
                  ? produit.fournisseur.key
                  : undefined,
              }
              semenceValues.produits.push(produitValues)
            })
          }
          modaliteValues.semences.push(semenceValues)
        })
      }

      if (typeof values.passages !== 'undefined') {
        values.passages.forEach((passage, index) => {
          if (passage) {
            const passagesValues = {
              facteur_id: values.factors[index].id,
              passage_id: values.factors[index].passage_id,
              produits: [],
            }
            if (passage.produits && passage.produits.length > 0) {
              passage.produits.forEach((produit) => {
                if (this.containsNull(produit) === false) {
                  const produitValues = {
                    id: produit?.id,
                    produit_id: produit.produit ? produit.produit.key : undefined,
                    dose: produit.dose,
                    unite_uid: produit.unite ? produit.unite.key : undefined,
                    fournisseur_id: produit.fournisseur
                      ? produit.fournisseur.key
                      : undefined,
                  }
                  passagesValues.produits.push(produitValues)
                }
              })
            }

            modaliteValues.passages.push(passagesValues)
          }
        })
      }

      if (typeof values.valeurspersonalise !== 'undefined') {
        values.valeurspersonalise.forEach((valeur, index) => {
          if (valeur && !Object.values(valeur).every(empty)) {
            const personaliseValues = {
              id: valeur?.id,
              valeur: valeur.valeur,
              facteur_id: values.factors[index].id,
              variable_id: values.factors[index].variable_id,
              type: valeur?.type,
            }

            modaliteValues.valeurs.push(personaliseValues)
          }
        })
      }
      if (this.edit_modality) {
        this.updateModality(modaliteValues)
      } else {
        this.addModality(modaliteValues)
      }
    },
    loadData() {
      this.loading = true
      this.emitter.emit('open-loader')
      this.fetchService
        .get(`protocole/${this.protocol_id}/modalite/${this.modality_id}`)
        .then((reponseModality) => {
          this.modality = reponseModality.data
          this.canEditModa = this.modality?.est_enfant !== true

          if (!this.canEditModa) {
            this.semences.items.group[0].options = { disabled: 'disabled' }
            this.semences.items.group[5].options = { disabled: 'disabled' }
          }

          Promise.all([
            this.fetchService
              .get(
                `protocole/${this.protocol_id}/modalite/${this.modality_id}/semence`, {
                  limit: 0,
                },
              )
              .then((responseSemence) => {
                this.modality.semences = responseSemence.data
              }),
          ]).then(() => {
            this.setData(this.modality)
            this.loading = false
            this.emitter.emit('close-loader')
          })
        })
    },
    async setData(modalityData = null) {
      this.loading = true
      const factors = []
      const semences = []
      const valeurspersonalise = []
      const passages = []

      await this.fetchService
        .get('dictionnaire/type/modalite')
        .then((reponseTypeModality) => {
          this.typemodalite = reponseTypeModality.data.map((type) => ({
            label: type.designation,
            value: type.uid,
          }))
        })

      let factorIndex = 0

      // eslint-disable-next-line no-restricted-syntax
      for (const header of this.headers) {
        let factor = { id: header.id }

        if (factorIndex > 0) {
          header.before_factor_id = this.headers[factorIndex - 1].id
        }

        if (header.passage_id) {
          factor = { ...factor, ...{ passage_id: header.passage_id } }
        }

        if (header.variable_id) {
          factor = { ...factor, ...{ variable_id: header.variable_id } }
        }

        // SEMENCE
        if (modalityData && modalityData.semences) {
          const valeurs = modalityData.semences.filter(
            (valeur) => header.id === valeur.facteur?.id,
          )

          if (valeurs) {
            const tmpobj = []
            valeurs.map((valeur) => {
              tmpobj.push({
                id: valeur?.id,
                produit: valeur?.produit
                  ? { key: valeur.produit.id, value: valeur.produit.designation }
                  : null,
                traitementsemence: valeur?.traitementsemence,
                densite: valeur?.densite,
                unite: valeur?.unite
                  ? { key: valeur.unite.uid, value: valeur.unite.valeur }
                  : null,
                pmg: valeur?.pmg,
                fournisseur: valeur?.fournisseur
                  ? {
                    key: valeur.fournisseur.id,
                    value: valeur.fournisseur.nom,
                  }
                  : null,
              })

              return null
            })
            this.repeatables[factorIndex] = tmpobj
            semences.push({ produits: tmpobj, facteur_id: header.id })
          }
        }

        // facteur perso
        if (modalityData && header.variable_id) {
          // eslint-disable-next-line no-loop-func, no-await-in-loop
          const valeursResponse = await this.fetchService.get(
            `protocole/${this.protocol_id}/facteur/${header.id}/variable/${header.variable_id}/valeur`,
            {
              filters: `modalite.id:eq(${this.modality_id})`,
            },
          )

          const valeur = valeursResponse.data.shift()
          let varvalues = {}
          varvalues = {
            id: valeur?.id,
            valeur: valeur?.valeur,
            type: header.variables[0].type.format_variable.uid,
          }

          if (header.variables[0].type.format_variable.uid === 'LISTE') {
            // eslint-disable-next-line no-loop-func, no-await-in-loop
            const referentielResponse = await this.fetchService.get(
              `dictionnaire/referentiel/${header.variables[0].type.referentiel.id}/valeur`,
              {
                filters: `uid:eq(${valeur?.valeur})`,
              },
            )
            if (referentielResponse.data.length > 0) {
              const referentiel = referentielResponse.data.shift()
              varvalues.valeur = { key: referentiel.uid, value: referentiel.valeur }
            }
          }

          // Complétement dégueu, mais aucune autre solution trouvée
          // eslint-disable-next-line
          header.personalizedValue = varvalues

          valeurspersonalise[factorIndex] = varvalues
        }

        // passages
        if (modalityData && header.passage_id) {
          // eslint-disable-next-line no-loop-func, no-await-in-loop
          await this.fetchService
            .get(`protocole/${this.protocol_id}/facteur/${header.id}/passage/${header.passage_id}/produit`,
              {
                filters: `modalite.id:eq(${this.modality_id})`,
              })
            // eslint-disable-next-line no-loop-func
            .then((reponseproduit) => {
              const tmpobj = []
              reponseproduit.data.map((valeur) => {
                tmpobj.push({
                  id: valeur?.id,
                  dose: valeur?.dose,
                  produit: valeur?.produit
                    ? { key: valeur.produit.id, value: valeur.produit.designation }
                    : null,
                  unite: valeur?.unite
                    ? { key: valeur.unite.uid, value: valeur.unite.valeur }
                    : null,
                  fournisseur: valeur?.fournisseur
                    ? {
                      key: valeur.fournisseur.id,
                      value: valeur.fournisseur.nom,
                    }
                    : null,
                })

                return null
              })
              this.repeatables[factorIndex] = tmpobj
              passages[factorIndex] = { produits: tmpobj, passage_id: header.passage_id }
            })
        }

        factors.push(factor)
        factorIndex += 1
      }

      this.initValues = {
        factors,
      }

      if (modalityData) {
        const modalityValues = {
          id: modalityData?.id,
          designation: modalityData?.designation ?? '',
          numero: modalityData?.numero,
          ordre: modalityData?.ordre,
          destruction:
            modalityData.destruction !== null
              ? modalityData.destruction
              : undefined,
          designationauto:
            modalityData.designationauto !== null
              ? modalityData.designationauto
              : undefined,
          option:
            modalityData.option !== null
              ? modalityData.option
              : undefined,
          typemodalite: modalityData ? modalityData.type.uid : null,
          semences,
          valeurspersonalise,
          passages,
        }
        this.initValues = { ...this.initValues, ...modalityValues }
        this.saveInitValues = this.helperService.cloneObject(this.initValues)
        this.dataLoaded = true
      } else {
        const modalityValues = {
          id: null,
          passages,
          semences,
          valeurspersonalise,
          typemodalite: 'PROTOCOLE',
          destruction: false,
          designationauto: true,
          option: false,
        }
        this.initValues = { ...this.initValues, ...modalityValues }
        this.saveInitValues = this.helperService.cloneObject(this.initValues)
        this.dataLoaded = true
      }

      this.headers.forEach((header) => {
        if (['SEMENCES', 'FERTILISATION', 'SANTEVEGETALE'].includes(header.type.uid)) {
          this.loading = false
          this.updateDefaultData()
        } else {
          this.loading = false
          this.updateDefaultData()
        }
      })
    },

    addModality(modality) {
      this.fetchService
        .post(`protocole/${this.protocol_id}/modalite`, modality.modalite)
        .then((returnModality) => {
          if (modality.semences.length > 0) {
            modality.semences.map((semence) => {
              const arrayOfProduct = []
              semence.produits.map((produit) => {
                arrayOfProduct.push({
                  ...produit,
                  ...{ modalite_id: returnModality.data.id },
                })
                return null
              })

              return this.fetchService.post(
                `protocole/${this.protocol_id}/modalite/${returnModality.data.id}/semence`,
                arrayOfProduct,
              )
            })
          }

          if (modality.passages.length > 0) {
            modality.passages.map((passage) => {
              const arrayOfProduct = []
              passage.produits.map((produit) => {
                arrayOfProduct.push({
                  ...produit,
                  ...{ modalite_id: returnModality.data.id },
                })
                return null
              })
              return this.fetchService.post(
                `protocole/${this.protocol_id}/facteur/${passage.facteur_id}/passage/${passage.passage_id}/produit`,
                arrayOfProduct,
              )
            })
          }

          if (modality.valeurs.length > 0) {
            modality.valeurs.map((valeur) => {
              let valeurModa = valeur.valeur

              if (typeof valeur.valeur === 'object') {
                valeurModa = valeur.valeur.key
              }
              return this.fetchService.post(
                `protocole/${this.protocol_id}/facteur/${valeur.facteur_id}/variable/${valeur.variable_id}/valeur`,
                {
                  valeur: valeurModa,
                  modalite_id: returnModality.data.id,
                },
              )
            })
          }

          this.repeatables = []
          this.loading = false
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La modalité a bien été ajouté.',
          })
          this.emitter.emit('refresh-modalities-table')
          this.close()
        })
    },
    async updateModality(modality) {
      await this.fetchService
        .put(
          `protocole/${this.protocol_id}/modalite/${this.modality_id}`,
          modality.modalite,
        )
        .then(async () => {
          if (modality.semences.length > 0) {
            await this.updateSemences(modality)
          }

          if (modality.passages.length > 0) {
            await this.updatePassages(modality)
          }

          if (modality.valeurs.length > 0) {
            modality.valeurs.map((valeur) => {
              if (valeur.valeur === undefined) {
                return null
              }

              let valeurModa = valeur.valeur

              if (typeof valeur.valeur === 'object') {
                valeurModa = valeur.valeur?.key
              }

              if (valeur.id) {
                return this.fetchService.delete(
                  // eslint-disable-next-line
                  `protocole/${this.protocol_id}/facteur/${valeur.facteur_id}/variable/${valeur.variable_id}/valeur/${valeur.id}`
                ).then(() => {
                  if (valeurModa) {
                    return this.fetchService.post(
                      // eslint-disable-next-line
                      `protocole/${this.protocol_id}/facteur/${valeur.facteur_id}/variable/${valeur.variable_id}/valeur`,
                      {
                        valeur: valeurModa,
                        modalite_id: this.modality_id,
                      },
                    )
                  }
                  return null
                })
              }
              return this.fetchService.post(
                `protocole/${this.protocol_id}/facteur/${valeur.facteur_id}/variable/${valeur.variable_id}/valeur`,
                {
                  valeur: valeurModa,
                  modalite_id: this.modality_id,
                },
              )
            })
          }

          this.resetData()

          this.loading = false
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La modalité a bien été mise à jour.',
          })
          this.emitter.emit('refresh-modalities-table')
          this.close()
        })
    },
    async updateSemences(modality) {
      const promises = modality.semences.map(async (facteur) => {
        const saveSemences = this.saveInitValues.semences.find(
          (item) => item?.facteur_id === facteur.facteur_id,
        )

        const updatePromise = new Promise((resolve) => {
          const arrayOfProduct = []

          facteur.produits.forEach((produit) => {
            arrayOfProduct.push({
              ...produit,
              ...{ modalite_id: this.modality_id },
            })
          })

          const arrayOfOldProduct = []
          if (saveSemences) {
            saveSemences.produits.forEach((oldProduit) => {
              arrayOfOldProduct.push(
                {
                  densite: oldProduit?.densite,
                  fournisseur_id: oldProduit.fournisseur?.key,
                  id: oldProduit.id,
                  pmg: oldProduit?.pmg,
                  traitementsemence: oldProduit?.traitementsemence,
                  modalite_id: this.modality_id,
                  produit_id: oldProduit?.produit?.key,
                  unite_uid: oldProduit?.unites?.key,
                },
              )
            })
          }

          const arrayDiff = this.helperService.arrayDiff(arrayOfProduct, arrayOfOldProduct)
          const promiseArray = []

          if (arrayDiff.added.length > 0) {
            promiseArray.push(this.addSemence(arrayDiff.added))
          }

          if (arrayDiff.updated.length > 0) {
            promiseArray.push(this.updateSemence(arrayDiff.updated))
          }

          if (arrayDiff.deleted.length > 0) {
            promiseArray.push(this.deleteSemence(arrayDiff.deleted))
          }

          const resolveAll = Promise.all(promiseArray).then(() => 'ok')
          resolve(resolveAll)
        })
        return updatePromise
      })
      const updatePromise = await Promise.all(promises)
      console.log(updatePromise)
    },
    async updatePassages(modality) {
      const promises = modality.passages.map(async (passage) => {
        const savePassages = this.saveInitValues.passages.find(
          (item) => item?.passage_id === passage.passage_id,
        )
        const updatePromise = new Promise((resolve) => {
          const arrayOfProduct = []
          passage.produits.forEach((produit) => {
            arrayOfProduct.push({
              ...produit,
              ...{ modalite_id: this.modality_id },
            })
          })
          const arrayOfOldProduct = []
          if (savePassages) {
            savePassages.produits.forEach((oldProduit) => {
              arrayOfOldProduct.push(
                {
                  dose: oldProduit.dose,
                  fournisseur_id: oldProduit.fournisseur.key,
                  id: oldProduit.id,
                  modalite_id: this.modality_id,
                  produit_id: oldProduit.produit.key,
                  unite_uid: oldProduit.unite.key,
                },
              )
            })
          }

          const toUpdate = arrayOfProduct.filter((x) => arrayOfOldProduct.find((item) => item.id === x.id))
          const toDelte = arrayOfOldProduct.filter((x) => !arrayOfProduct.find((item) => item.id === x.id))
          const toAdd = arrayOfProduct.filter((x) => !arrayOfOldProduct.find((item) => item.id === x.id))

          const promiseArray = []
          if (toDelte.length > 0) {
            promiseArray.push(this.deleteProduct(toDelte, passage))
          }

          if (toUpdate.length > 0) {
            promiseArray.push(this.updateProduct(toUpdate, passage))
          }

          if (toAdd.length > 0) {
            promiseArray.push(this.addProduct(toAdd, passage))
          }

          const resolveAll = Promise.all(promiseArray).then(() => 'ok')
          resolve(resolveAll)
        })
        return updatePromise
      })
      const updatePromise = await Promise.all(promises)
      console.log(updatePromise)
    },

    addSemence(toAdd) {
      return this.fetchService.post(
        `protocole/${this.protocol_id}/modalite/${this.modality_id}/semence`,
        toAdd,
      )
    },
    updateSemence(toUpdate) {
      return this.fetchService.put(
        `protocole/${this.protocol_id}/modalite/${this.modality_id}/semence`,
        toUpdate,
      )
    },
    deleteSemence(toDelte) {
      return this.fetchService.delete(
        `protocole/${this.protocol_id}/modalite/${this.modality_id}/semence`,
        toDelte,
      )
    },

    addProduct(toAdd, passage) {
      return this.fetchService.post(
        `protocole/${this.protocol_id}/facteur/${passage.facteur_id}/passage/${passage.passage_id}/produit`,
        toAdd,
      )
    },
    updateProduct(toUpdate, passage) {
      return this.fetchService.put(
        `protocole/${this.protocol_id}/facteur/${passage.facteur_id}/passage/${passage.passage_id}/produit`,
        toUpdate,
      ).then(() => 'ok')
    },
    deleteProduct(toDelte, passage) {
      return this.fetchService.delete(
        `protocole/${this.protocol_id}/facteur/${passage.facteur_id}/passage/${passage.passage_id}/produit`,
        toDelte,
        { filters: `modalite.id:eq(${this.modality_id})` },
      )
    },
    resetData() {
      this.dataLoaded = false
      this.initValues = null
      this.repeatables = []
      this.formattedValue = []
      this.headers.forEach((header) => {
        if ('personalizedValue' in header) {
          // eslint-disable-next-line
          delete header.personalizedValue
        }
      })
    },
    async getFirstSupplier(productID, i, j, type = 'passage') {
      if (productID) {
        await this.fetchService
          .get(`produit/${productID}`, {})
          .then((response) => {
            if (response.data?.noms_commerciaux.length > 0) {
              const supplier = response.data?.noms_commerciaux[0]
              if (type === 'passage') {
                if (this.currentFormValues.passages[i].produits[j].fournisseur === null) {
                  this.initValues.passages[i].produits[j].fournisseur = {
                    key: supplier.fournisseur.id,
                    value: supplier.fournisseur.nom,
                  }
                }
              } else if (this.currentFormValues.semences[i].produits[j].fournisseur === null) {
                this.initValues.semences[i].produits[j].fournisseur = {
                  key: supplier.fournisseur.id,
                  value: supplier.fournisseur.nom,
                }
              }
            }
            return null
          })
      } else if (productID === undefined) {
        if (type === 'passage') {
          this.initValues.passages[i].produits[j].fournisseur = null
        } else {
          this.initValues.semences[i].produits[j].fournisseur = null
        }
      }
    },

    getFiltredProduct(type) {
      const items = this.produits[type].map((d) => ({
        id: d.id,
        designation: d.designation,
      }))

      if (type === 'SEMENCES') {
        this.semences.items.group[0].meta.items = items
        return this.semences.items
      }

      if (type === 'SANTEVEGETALE') {
        this.santevegetale.items.group[0].meta.items = items
        return this.santevegetale.items
      }

      if (type === 'FERTILISATION') {
        this.fertilisation.items.group[0].meta.items = items
        return this.fertilisation.items
      }

      this.fertilisation.items.group[0].meta.items = items
      return this.fertilisation.items
    },
    updateCurrentFormValues(values) {
      this.currentFormValues = values
    },

    updatePassage(values, index) {
      values.formattedData.forEach((item, j) => {
        this.getFirstSupplier(item?.produit?.key, index, j)
      })
      this.updateDefaultData()
    },

    updateSemenceSupplier(values, index) {
      values.formattedData.map((item, j) => {
        this.getFirstSupplier(item?.produit?.key, index, j, 'semence')
        return null
      })
      this.updateDefaultData()
    },

    updateDefaultData() {
      this.defaultDesignation = null
      const designationValues = []
      this.currentFormValues.factors.forEach((factor, index) => {
        let localValue = '-'

        if (this.currentFormValues?.passages) {
          if (this.currentFormValues.passages[index]) {
            if (this.currentFormValues.passages[index].produits && this.currentFormValues.passages[index].produits !== undefined) {
              const passageProducts = []
              this.currentFormValues.passages[index].produits.forEach((produit) => {
                if (produit.produit) {
                  let localdescription = produit.produit.value
                  if (produit.dose) {
                    localdescription = `${localdescription} ${produit.dose}`
                  }
                  passageProducts.push(localdescription)
                }
              })
              if (passageProducts.length > 0) {
                localValue = passageProducts.join(' + ')
              }
            }
          }
        }

        if (this.currentFormValues?.semences) {
          if (this.currentFormValues.semences[index]) {
            if (this.currentFormValues.semences[index].produits && this.currentFormValues.semences[index].produits !== undefined) {
              const passageProducts = []
              this.currentFormValues.semences[index].produits.forEach((produit) => {
                if (produit.produit) {
                  let localdescription = produit.produit.value
                  if (produit.dose) {
                    localdescription = `${localdescription} ${produit.dose}`
                  }
                  passageProducts.push(localdescription)
                }
              })
              if (passageProducts.length > 0) {
                localValue = passageProducts.join(' + ')
              }
            }
          }
        }

        designationValues.push(localValue)
      })

      this.defaultDesignation = designationValues.join(' / ')
    },

    updateErrors(errors) {
      this.errors = errors
    },
  },
}
</script>
<style lang="scss" scoped>
.no-margin-bottom {
  margin-bottom: 0 !important;
}
</style>
