<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Facteurs étudiés</h2>
    </Container>
  </div>

  <Container>
    <div class="table-button" v-if="protocol?.statut?.uid !== 'ENCOURS'">
      <ToolDropdown dropdown-align="left" toggle-btn-text="Ajouter un facteur"
        v-if="factorNb < 4 && helperService.userHasPermission('protocolefacteur_oadd')">
        <MenuItem v-for="factor in factors" :key="factor.id" :item-data="{
          label: factor.designation,
        }" @click="openModal(factor.uid, protocol)" />
      </ToolDropdown>

      <Btn v-if="factorNb >= 4" disabled text="Nombre maximum de facteurs" color="primary" />

      <template v-if="headers.length > 0">
        <Btn v-if="helperService.userHasPermission('protocolemodalite_oadd')" text="Ajouter une modalité"
          color="primary" @click="openModal('addModality', protocol)" inline hollow />
      </template>
    </div>

    <Section>
      <div class="v-padding-half">
        <Btn
          v-if="helperService.userHasPermission('protocolemodalite_oremove') && protocol?.statut?.uid !== 'ENCOURS' && modalities.length > 1"
          :text="btnText" color="default" @click="toggleAll" />
        <Btn :class="(modalities.length > 1) ? 'h-margin-half' : ''"
          v-if="helperService.userHasPermission('protocolemodalite_oremove') && protocol?.statut?.uid !== 'ENCOURS' && selectedItems.length > 0"
          text="Supprimer des modalites" color="accent" @click="openModal('deleteAll', selectedItems)" />
      </div>
      <ModalitiesTable :protocol_id="protocolId" checkbox checkboxKey='id' :tools="true"
        :defaultSelectedItems="selectedItems" @headers="setHeaders" @modalities="setModalities"
        @editItem="moveXModa($event, true)">
        <template v-if="protocol?.statut?.uid !== 'ENCOURS' &&
          protocol.date_partage === null &&
          helperService.userHasPermission(
            'protocolefacteur_oremove,protocolefacteur_oedit',
            'OR'
          )
          " v-slot:header-tools="{ header }">
          <ToolDropdown>
            <MenuItem v-if="helperService.userHasPermission('protocolefacteur_oedit')" :item-data="{
              label: 'Éditer le facteur',
              iconName: 'create',
            }" @click="openModal(header.type.uid, header)" />
            <MenuItem v-if="helperService.userHasPermission('protocolefacteur_oremove')" :item-data="{
              label: 'Supprimer le facteur',
              iconName: 'trash-alt',
            }" @click="openModal('deleteFacteur', header)" />
          </ToolDropdown>
        </template>

        <template v-slot:tools="{ item }">

          <Btn v-if="helperService.userHasPermission('protocolemodalite_oedit')
            && protocol?.statut?.uid !== 'ENCOURS'" color="white" icon="shuffle" title="Déplacer la modalité"
            @click="moveXModa(item.item, false)" />
          <Btn v-if="helperService.userHasPermission('protocolemodalite_duplicate')
            && protocol?.statut?.uid !== 'ENCOURS'
          " icon="copy" color="white" title="Dupliquer la modalité"
            @click="openModal('duplicateModality', item.item)" />
          <Btn v-if="helperService.userHasPermission('protocolemodalite_oedit')" @click="editModality(item.item)"
            icon="create" color="white" title="Modifier la modalité" />
          <Btn v-if="helperService.userHasPermission('protocolemodalite_oremove')
            && protocol?.statut?.uid !== 'ENCOURS'" icon="trash-alt" color="white" title="Supprimer la modalité"
            @click="openModal('deleteModality', item.item)" />
        </template>
      </ModalitiesTable>
    </Section>
  </Container>

  <!-- Factors -->
  <!-- Factor personnalisé -->
  <Modal :title="`Ajouter un facteur personnalisé`" :active="modal.PERSONNALISE" :key="componentKey"
    :modalValidationSchema="personnaliseValidationSchema" @modal-close="modal.PERSONNALISE = false" :containForm="true"
    :data="modalData" size="lg" no-scroll @modal-form-submitted="handleSubmit($event, 'PERSONNALISE')">
    <template v-slot:modal-body>
      <Input id="id_facteur" hidden />
      <Input id="designation" label="Désignation" />
      <SelectExtended label="Choix de la variable facteur" id="variables" option-key="id" option-value="designation"
        apiEndpoint="dictionnaire/type/facteur/variable" :errors="errors" />
      <div class="margin-bottom-dropdown">
        <SelectExtended v-if="showPersonnalizedUnite" id="unite" label="Unité" option-key="uid" option-value="valeur"
          apiEndpoint="unite" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.PERSONNALISE = false" />
      <Btn btnType="submit" text="Ajouter" color="primary" />
    </template>
  </Modal>

  <!-- Factor SANTEVEGETALE-->
  <Modal :title="`Ajouter un facteur de santé végétale`" :active="modal.SANTEVEGETALE" :key="componentKey"
    :modalValidationSchema="fertiValidationSchema" @modal-close="modal.SANTEVEGETALE = false" :containForm="true"
    :data="modalData" size="lg" no-scroll @modal-form-submitted="handleSubmit($event, 'SANTEVEGETALE')">
    <template v-slot:modal-body="{ data }">
      <div class="margin-bottom">
        <Input id="designation" label="Désignation du facteur" />
      </div>
      <div class="margin-bottom-dropdown">
        <RepeatableInputs v-if="repeatables.stade.group[0].meta.apiEndpoint && modal.SANTEVEGETALE
        " label="Choix du stade" id="stades" :default-selection="data.stades" :items="repeatables.stade"
          :errors="errors" n-cols="1" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.SANTEVEGETALE = false" />
      <Btn btnType="submit" text="Ajouter" color="primary" />
    </template>
  </Modal>

  <!-- Factor FERTILISATION-->
  <Modal :title="`Ajouter un facteur de fertilisation`" :active="modal.FERTILISATION" :key="componentKey"
    :modalValidationSchema="fertiValidationSchema" @modal-close="modal.FERTILISATION = false" :data="modalData"
    :containForm="true" size="lg" no-scroll @modal-form-submitted="handleSubmit($event, 'FERTILISATION')"
    @modal-form-errors="updateErrors">
    <template v-slot:modal-body="{ data }">
      <div class="margin-bottom">
        <Input id="id_facteur" hidden />
        <Input id="designation" label="Désignation du facteur" />
      </div>
      <div class="margin-bottom-dropdown">
        <RepeatableInputs v-if="repeatables.stade.group[0].meta.apiEndpoint && modal.FERTILISATION
        " label="Choix du stade" id="stades" :default-selection="data.stades" :items="repeatables.stade"
          :errors="errors" n-cols="1" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.FERTILISATION = false" />
      <Btn btnType="submit" text="Ajouter" color="primary" />
    </template>
  </Modal>

  <!-- Factor SEMENCE -->
  <Modal :title="`Ajouter un facteur semences`" :active="modal.SEMENCES" :data="modalData" :containForm="true"
    :modalValidationSchema="semencesValidationSchema" @modal-close="modal.SEMENCES = false"
    @modal-form-submitted="handleSubmit($event, 'SEMENCES')" no-scroll>
    <template v-slot:modal-body>
      <div class="margin-bottom">
        <Input id="id_facteur" hidden />
        <Input id="designation" label="Désignation du facteur" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.SEMENCES = false" />
      <Btn btnType="submit" text="Ajouter" color="primary" />
    </template>
  </Modal>

  <!-- duplicateModality modality -->
  <Modal title="Dupliquer la modalité&nbsp;?" :active="modal.duplicateModality" :data="modalData"
    @modal-close="modal.duplicateModality = false">
    <template v-slot:modal-body>
      <p>Voulez vous vraiment dupliquer cette modalité&nbsp;?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.duplicateModality = false" />
      <Btn text="Dupliquer" @click="duplicateModality(data)" icon="copy" color="primary" />
    </template>
  </Modal>

  <!-- Delete modality -->
  <Modal title="Supprimer la modalité&nbsp;?" :active="modal.deleteModality" :data="modalData"
    @modal-close="modal.deleteModality = false">
    <template v-slot:modal-body>
      <p>Voulez vous vraiment supprimer cette modalité&nbsp;?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteModality = false" />
      <Btn text="Supprimer" @click="deleteModality(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>

  <!-- Delete modalitys -->
  <Modal title="Supprimer des modalités&nbsp;?" :active="modal.deleteAll" :data="modalData"
    @modal-close="modal.deleteAll = false">
    <template v-slot:modal-body>
      <p>Voulez vous vraiment supprimer ces modalités&nbsp;?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteAll = false" />
      <Btn text="Supprimer" @click="deleteAll(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>

  <!-- Delete factor -->
  <Modal title="Supprimer le facteur&nbsp;?" :active="modal.deleteFacteur" :data="modalData"
    @modal-close="modal.deleteFacteur = false">
    <template v-slot:modal-body>
      <p>Voulez vous vraiment supprimer ce facteur&nbsp;?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteFacteur = false" />
      <Btn text="Supprimer" @click="deleteFactor(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>

  <!-- Add modalitie -->
  <AddEditModaliteModal v-if="headers.length > 0 && !isLoading" :protocol_id="protocolId"
    :modalActive="modal.addModality" :headers="headers" :modalities="modalities" :modalitiesNb="modalitiesNb"
    :produits="produits" compkey="1a" @modal-close="modal.addModality = false" />

  <!-- update modalitie -->
  <AddEditModaliteModal v-if="modality_id && !isLoading" :protocol_id="protocolId" :modalActive="modal.editModality"
    :headers="headers" :modalities="modalities" :modality_id="modality_id" :modalitiesNb="modalitiesNb"
    :produits="produits" :edit_modality="true" :editAll="!(protocol?.statut?.uid === 'ENCOURS')" compkey="2b"
    @modal-close="modal.editModality = false" />
</template>

<script>
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Modal from '@/components/layout/Modal.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Btn from '@/components/base/Btn.vue'
import ModalitiesTable from '@/views/componentsViews/modalities/ModalitiesTable.vue'
import AddEditModaliteModal from '@/views/componentsViews/modalities/AddEditModaliteModal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'StudiedExperimentAddEditSubview',

  components: {
    MenuItem,
    ToolDropdown,
    ModalitiesTable,
    AddEditModaliteModal,
    Btn,
    RepeatableInputs,
    Modal,
    SelectExtended,
    Input,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    validationSchema: {
      type: [Array, Object],
      default: undefined,
    },
    pstep: {
      type: [Number, String],
    },
  },

  data(props) {
    const { validationSchema } = props
    const vs = validationSchema[0]
    const { isSubmitting } = this.formService.initFrom(vs)

    const semencesValidationSchema = this.yup.object({
      id_facteur: this.yup.string().nullable(),
      designation: this.yup.string().nullable(),
    })

    const personnaliseValidationSchema = this.yup.object({
      id_facteur: this.yup.string().nullable(),
      designation: this.yup.string().nullable(),
    })

    const fertiValidationSchema = this.yup.object({
      id_facteur: this.yup.string().nullable(),
      designation: this.yup.string().nullable(),
      stades: this.yup
        .array()
        .of(
          this.yup.object().shape({
            stade: this.yup.object().required(),
          }),
        )
        .nullable(),
    })

    return {
      vs,
      isSubmitting,
      isLoading: true,
      errors: null,
      personnaliseValidationSchema,
      semencesValidationSchema,
      fertiValidationSchema,
      protocol: {},
      produits: {},
      step: 3,
      routeName: this.$route.name,
      previousRouteName: null,
      // Protocol
      protocolId: this.$route.params.id,

      // Modalities
      modalities: [],
      modalitiesNb: 0,
      modality_id: null,
      headers: [],

      // Factors
      factors: [],
      factorNb: 0,
      variables: [],

      modalitiesorder: null,

      // Modals
      modalData: {},
      modal: {
        PERSONNALISE: false,
        SANTEVEGETALE: false,
        FERTILISATION: false,
        SEMENCES: false,
        addModality: false,
        editModality: false,
        deleteModality: false,
        duplicateModality: false,
        deleteFacteur: false,
        deleteAll: false,
      },
      showPersonnalizedUnite: false,

      // Repeatable

      repeatables: {
        stade: {
          group: [
            {
              name: 'stade',
              meta: {
                optionKey: 'uid',
                optionValue: 'designation_calculee',
                apiEndpoint: 'dictionnaire/stade',
                required: true,
                apiParams: { sort: 'ordre.asc' },
              },
            },
          ],
        },
      },

      componentKey: 0,
      loading: false,
      selectedItems: [],
      btnText: 'Tout sélectionner',
    }
  },

  emits: ['set-step', 'populate', 'set-route', 'formSubmitted', 'setSubmitted'],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  mounted() {
    this.isLoading = true
    const routeParams = {}
    if (this.$route.params.id) {
      if (this.protocol.thematique) {
        this.ste = this.protocol.thematique
      }
      routeParams.id = this.$route.params.id
    }

    if (this.$route.name.includes('Edit')) {
      this.previousRouteName = 'protocolExperimentImplementationEdit'
      this.nextRouteName = 'protocolCoverageExperimentEdit'
    } else {
      this.previousRouteName = 'protocolExperimentImplementationAdd'
      this.nextRouteName = 'protocolCoverageExperimentAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: this.previousRouteName,
      nextRouteName: this.nextRouteName,
      routeParams,
    })
    this.getProtocol()
    this.getDictionaryFactors()
    this.emitter.on('selected-items', this.selectItems)
  },

  methods: {
    setModalFormService(event) {
      this.modalFormService = event
    },
    /**
     * Modal
     * @param modal
     * @param data
     */
    openModal(modal, data) {
      console.log('data', data)
      this.modalData = data
      this.modal[modal] = true
    },

    selectItems(event) {
      this.selectedItems = event.selected
      if (this.selectedItems.length === this.modalities.length) {
        this.btnText = 'Tout désélectionner'
      } else {
        this.btnText = 'Tout sélectionner'
      }
    },
    toggleAll() {
      if (this.selectedItems.length < this.modalities.length) {
        this.selectedItems = this.modalities.map((m) => m.id)
        this.btnText = 'Tout désélectionner'
      } else {
        this.selectedItems = []
        this.btnText = 'Tout sélectionner'
      }
    },

    /** *********************
     * GETTERS
     ********************* */
    // Protocoles
    getProtocol() {
      this.fetchService
        .get(`protocole/${this.$route.params.id}`)
        .then((response) => {
          this.protocol = response.data

          const cultures = this.protocol.cultures.map((item) => item.uid)

          if (cultures.length > 0) {
            this.repeatables.stade.group[0].meta.apiParams = {
              sort: 'ordre.ASC',
              filters: `culture.uid:in(${cultures})`,
            }
          }

          this.fetchService.get(`protocole/${this.protocol.id}/facteur`)
            .then((facteurresponse) => {
              this.factorNb = facteurresponse.data.length
            })

          this.getProducts()
        })
    },

    getProducts() {
      Promise.all([
        this.fetchService.get('produit', {
          limit: 0,
          sort: 'designation.ASC',
          filters: 'type.uid:eq(SEMENCE)|n|actif:ist()',
          distinct: 1,
        }),
        this.fetchService.get('produit', {
          limit: 0,
          sort: 'designation.ASC',
          filters: 'type.uid:eq(FERTILISANT)|n|actif:ist()',
          distinct: 1,
        }),
        this.fetchService.get('produit', {
          limit: 0,
          sort: 'designation.ASC',
          filters: 'type.uid:eq(SANTEVEGETALE)|n|actif:ist()',
          distinct: 1,
        }),
      ]).then((values) => {
        this.produits = {
          SEMENCES: values[0].data,
          FERTILISATION: values[1].data,
          SANTEVEGETALE: values[2].data,
        }
        this.isLoading = false
        console.log('this.isLoading', this.isLoading)
        this.$emit('populate', this.protocol)
      })
    },

    /**
     * Get factor dictionary
     */
    getDictionaryFactors() {
      this.fetchService.get('dictionnaire/type/facteur').then((response) => {
        this.factors = response.data
      })
    },

    /** *********************
     * Setters
     ********************* */
    setHeaders(headers) {
      this.headers = headers
      if (this.protocol.id) {
        this.fetchService.get(`protocole/${this.protocol.id}/facteur`)
          .then((response) => {
            this.factorNb = response.data.length
          })
      }
    },

    setModalities(modalities) {
      this.modalities = modalities
      this.modalitiesNb = modalities.length
    },

    /** *********************
     * Process forms
     ********************* */
    handleSubmit(values, type = null) {
      this.addEditFactor(values, type)
    },

    // Factors

    /**
     * Add a factor
     * @param values
     * @param factor
     */
    async addEditFactor(values, factor) {
      let success = false
      let endpoint = `protocole/${this.protocol.id}/facteur`
      let method = 'post'

      if (['SANTEVEGETALE', 'FERTILISATION'].includes(factor)) {
        if (values.stades === undefined || values.stades.length === 0) {
          this.errors = {}
          this.errors.stades = 'Le champs est obligatoire'
          return
        }
      }

      if (values?.id_facteur !== undefined || values?.id_facteur) {
        method = 'put'
        endpoint = `protocole/${this.protocol.id}/facteur/${values.id_facteur}`
      }

      const response = await this.fetchService[method](endpoint, {
        type_uid: factor,
        designation: values.designation,
      })

      if (response?.meta?.success) {
        if (factor === 'SEMENCES') {
          success = true
        }

        if (factor === 'PERSONNALISE') {
          const obj = {
            type_id: values.variables.key,
          }
          await this.fetchService.post(`protocole/${this.protocol.id}/facteur/${response.data.id}/variable`,
            obj)
          success = true
        }

        if (values.stades && values.stades.length > 0) {
          const initPassages = await this.fetchService.get(
            `protocole/${this.protocol.id}/facteur/${response.data.id}/passage`,
          )

          const updatePromise = await new Promise((resolve) => {
            const initPassageArray = initPassages.data.map((stade) => {
              if (stade.stade) {
                return { id: stade.id, stade_uid: stade.stade.uid }
              }
              return null
            })

            const passageArray = values.stades.map((stade) => {
              if (stade.stade) {
                return { id: stade.stade.passage_id, stade_uid: stade.stade.key }
              }
              return null
            })

            const promiseArray = []
            const arrayDiff = this.helperService.arrayDiff(passageArray, initPassageArray)

            if (arrayDiff.added.length > 0) {
              promiseArray.push(this.addPassage(arrayDiff.added, response.data.id))
            }

            if (arrayDiff.updated.length > 0) {
              promiseArray.push(this.updatePassage(arrayDiff.updated, response.data.id))
            }

            if (arrayDiff.deleted.length > 0) {
              promiseArray.push(this.deletePassage(arrayDiff.deleted, response.data.id))
            }

            const resolveAll = Promise.all(promiseArray).then(() => true)
            resolve(resolveAll)
          })
          success = updatePromise
        }
      }

      if (success) {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le facteur a bien été ajouté.',
        })
        this.modal[factor] = false
        this.emitter.emit('refresh-modalities-table')
        this.errors = null
      } else {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Une erreur est survenue.',
        })
        this.modal[factor] = false
        this.emitter.emit('refresh-modalities-table')
      }
    },

    addPassage(toAdd, idFacteur) {
      return this.fetchService.post(
        `protocole/${this.protocol.id}/facteur/${idFacteur}/passage`,
        toAdd,
      )
    },
    updatePassage(toUpdate, idFacteur) {
      return this.fetchService.put(
        `protocole/${this.protocol.id}/facteur/${idFacteur}/passage`,
        toUpdate,
      )
    },
    deletePassage(toDelte, idFacteur) {
      return this.fetchService.delete(
        `protocole/${this.protocol.id}/facteur/${idFacteur}/passage`,
        toDelte,
      )
    },

    moveXModa(event, sendOrder) {
      if (!sendOrder) {
        this.emitter.emit('select-modalities-table', event)
      } else {
        this.fetchService
          .put(`protocole/${this.protocol.id}/modalite/${event.oldModa.id}`, {
            ordre: event.newModa.ordre,
          })
          .then(
            () => {
              this.fetchService.patch(`protocole/${this.protocolId}/ordonnancer`).then(() => {
                this.emitter.emit('alert', {
                  type: 'success',
                  content: 'La modalité a été modifié.',
                })
                this.emitter.emit('refresh-modalities-table')
                this.emitter.emit('select-modalities-table', event.oldModa)
              }, () => {
                this.emitter.emit('alert', {
                  type: 'success',
                  content: 'La modalité a été modifié.',
                })
                this.emitter.emit('refresh-modalities-table')
                this.emitter.emit('select-modalities-table', event.oldModa)
              })
            },
            () => {
              this.emitter.emit('refresh-modalities-table')
            },
          )
      }
    },

    moveModa(direction, data) {
      let newOrder = data.ordre
      if (direction === 'up') {
        newOrder -= 1
      } else {
        newOrder += 1
      }

      this.fetchService
        .put(`protocole/${this.protocol.id}/modalite/${data.id}`, {
          ordre: newOrder,
        })
        .then(
          this.fetchService.patch(`protocole/${this.protocolId}/ordonnancer`).then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La modalité a été modifié.',
            })
            this.emitter.emit('refresh-modalities-table')
          }, () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La modalité a été modifié.',
            })
            this.emitter.emit('refresh-modalities-table')
          }),
        )
    },

    duplicateModality(data) {
      this.emitter.emit('open-loader')
      this.fetchService
        .patch(`protocole/${this.protocol.id}/modalite/${data.id}/dupliquer`)
        .then(
          () => {
            this.fetchService.patch(`protocole/${this.protocolId}/ordonnancer`).then(() => {
              this.emitter.emit('refresh-modalities-table')
              this.emitter.emit('alert', {
                type: 'success',
                content: 'La modalité a bien été dupliquée.',
              })
              this.emitter.emit('close-loader')
            }, () => {
              this.emitter.emit('refresh-modalities-table')
              this.emitter.emit('alert', {
                type: 'success',
                content: 'La modalité a bien été dupliquée.',
              })
              this.emitter.emit('close-loader')
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content:
                'data' in responseError
                  ? responseError.data
                  : responseError.message,
            })
            this.emitter.emit('close-loader')
          },
        )
      this.modal.duplicateModality = false
    },

    editModality(data) {
      this.modality_id = data.id
      this.openModal('editModality')
    },

    deleteAll() {
      this.emitter.emit('open-loader')
      const promiseArray = []

      this.selectedItems.forEach((item) => {
        promiseArray.push(this.fetchService.delete(`protocole/${this.protocolId}/modalite/${item}`))
      })

      Promise.all(promiseArray).then(() => {
        this.fetchService.patch(`protocole/${this.protocolId}/ordonnancer`).then(() => {
          this.emitter.emit('refresh-modalities-table')
        })
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Les modalités ont été supprimées.',
        })
      }).finally(() => this.emitter.emit('close-loader'))
      this.selectedItems = []
      this.emitter.emit('close-loader')
      this.modal.deleteAll = false
    },

    /**
     * Delete a modality
     * @param data
     */
    deleteModality(data) {
      this.emitter.emit('open-loader')
      this.fetchService
        .delete(`protocole/${this.protocolId}/modalite/${data.id}`)
        .then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La modalité a bien été supprimée.',
            })
            this.emitter.emit('refresh-modalities-table')
          },
          (responseError) => {
            this.formService.handleApiError(responseError)
          },
        )
        .finally(() => this.emitter.emit('close-loader'))

      this.modal.deleteModality = false
    },
    /**
     * Delete a factor
     * @param data
     */
    deleteFactor(data) {
      this.emitter.emit('open-loader')
      this.fetchService
        .delete(`protocole/${this.protocolId}/facteur/${data.id}`)
        .then(
          () => {
            this.emitter.emit('close-loader')
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le facteur a bien été supprimé.',
            })
            this.emitter.emit('refresh-modalities-table')
          },
          (responseError) => {
            this.formService.handleApiError(responseError)
            this.emitter.emit('close-loader')
          },
        )

      this.modal.deleteFacteur = false
    },

    updateErrors(errors) {
      this.errors = errors
    },
  },
}
</script>

<style lang="scss" scoped>
.table-button {
  display: flex;
  gap: $gutter-half;
  z-index: 11;
  position: relative;
}

::v-deep(table) {
  min-height: 20rem;
}
</style>
